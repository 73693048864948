import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { ReactComponent as BookIconActive } from "../../assets/images/icons/ic-book-active.svg"
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-book-inactive.svg"
import { ReactComponent as CareersIconActive } from "../../assets/images/icons/ic-careers-active.svg"
import { ReactComponent as CareersIcon } from "../../assets/images/icons/ic-careers.svg"
import { ReactComponent as ChatIconActive } from "../../assets/images/icons/ic-chat-active.svg"
import { ReactComponent as ChatIcon } from "../../assets/images/icons/ic-chat.svg"
import { ReactComponent as HomeIconActive } from "../../assets/images/icons/ic-home-active.svg"
import { ReactComponent as HomeIcon } from "../../assets/images/icons/ic-home.svg"
import { ReactComponent as BellFillIcon } from "../../assets/images/icons/ic-notification-active.svg"
import { ReactComponent as BellIcon } from "../../assets/images/icons/ic-notification.svg"
import { ReactComponent as PathIconActive } from "../../assets/images/icons/ic-path-active.svg"
import { ReactComponent as PathIcon } from "../../assets/images/icons/ic-path.svg"
import { ReactComponent as PersonalityFillIcon } from "../../assets/images/icons/ic-personality-active.svg"
import { ReactComponent as PersonalityIcon } from "../../assets/images/icons/ic-personality.svg"
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png"
import { ReactComponent as Logo } from "../../assets/images/velv-black.svg"
import useWindowDimensions from "../../common/hooks/useWindowDimensions"
import MainContext from "../../common/MainContext"
import Button from "../Button"
import MessagesButton from "../MessagesButton"
import NotificationsDropdown from "../NotificationsDropdown"
import Hamburger from "./Hamburger"
import styles from "./TopNav.module.css"

const TopNav = () => {

    const navigate = useNavigate()
    const context = useContext(MainContext)
    const location = useLocation()
    const { width } = useWindowDimensions()

    const [path, setPath] = useState("/")
    const [isOpen, setIsOpen] = useState(false)

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        return () => {
            document.body.style.overflow = '';
        }
    }, [])

    useEffect(() => {
        if (width > 768) {
            setIsOpen(false)
        }
    }, [width])

    useEffect(() => {
        if (isOpen && width <= 768) {
            document.body.style.overflow = 'hidden';
        }
        else {
            // When the modal is hidden, we want to remain at the top of the scroll position
            document.body.style.overflow = '';
        }
    }, [isOpen, width])

    useEffect(() => {
        setPath(location.pathname)
    }, [location.pathname])


    function getIcon(page) {
        if (location.pathname === `/${page}`) {
            switch (page) {
                case 'courses':
                    return <BookIconActive className={styles.icon}></BookIconActive>
                case 'careers':
                    return <CareersIconActive className={styles.icon}></CareersIconActive>
                case '':
                    return <HomeIconActive className={styles.icon}></HomeIconActive>
                case 'messages':
                    return <ChatIconActive className={styles.icon}></ChatIconActive>
                case 'my-path':
                    return <PathIconActive className={styles.icon}></PathIconActive>
                case 'personalities':
                    return <PersonalityFillIcon style={{ width: '20px', height: '20px' }}></PersonalityFillIcon>
                default: return
            }
        }
        else {
            switch (page) {
                case 'courses':
                    return <BookIcon className={styles.icon}></BookIcon>
                case 'careers':
                    return <CareersIcon className={styles.icon}></CareersIcon>
                case '':
                    return <HomeIcon className={styles.icon}></HomeIcon>
                case 'messages':
                    return <ChatIcon className={styles.icon}></ChatIcon>
                case 'my-path':
                    return <PathIcon className={styles.icon}></PathIcon>
                case 'personalities':
                    return <PersonalityIcon style={{ width: '20px', height: '20px' }}></PersonalityIcon>
                default: return
            }
        }
    }

    return (
        <>
            <div className={`${styles.container} ${isOpen ? styles.open : ''} ${((scrollPosition > 70 || isOpen) || path !== '/') ? styles.filled : ''}`}>
                <div className={styles.header}>
                    {width > 768 &&
                        <>
                            <Logo className={styles.logo} onClick={() => { navigate("/") }} />
                            <div className={styles.menu}>
                                {context.hasSubscription &&
                                    <button
                                        className={`${styles.menuButton} ${path === '/' ? styles.active : ''}`}
                                        onClick={() => { navigate("/") }}>
                                        HOME
                                    </button>}
                                {/* <button
                                    className={`${styles.menuButton} ${path === '/courses' ? styles.active : ''}`}
                                    onClick={() => { navigate("/courses") }}>
                                    CORSI
                                </button>
                                <button
                                    className={`${styles.menuButton} ${path === '/careers' ? styles.active : ''}`}
                                    onClick={() => { navigate("/careers") }}>
                                    CARRIERE
                                </button> */}
                                {!context.user.isAnonymous && context.hasSubscription &&
                                    <button
                                        className={`${styles.menuButton} ${path === '/my-path' ? styles.active : ''}`}
                                        onClick={() => { navigate("/my-path") }}>
                                        IL MIO PERCORSO
                                    </button>
                                }
                                {/* <button
                                    className={`${styles.menuButton} ${path === '/personalities' ? styles.active : ''}`}
                                    onClick={() => { navigate("/personalities") }}>
                                    LA MIA PERSONALITÀ
                                </button> */}
                            </div>
                            <div className={styles.spacer}></div>
                            {context.user.isAnonymous &&
                                <Button inverse style={{ fontSize: '1.125rem', padding: '.7rem 2rem' }} onClick={() => { navigate("/login") }}>ACCEDI</Button>
                            }
                            {!context.user.isAnonymous &&
                                <>
                                    {context.hasSubscription &&
                                        <div style={{ display: 'flex', gap: '0.5rem' }}>
                                            <MessagesButton />
                                            <NotificationsDropdown />
                                        </div>
                                    }
                                    <button className={styles.userButton} onClick={() => {
                                        navigate("/profile")
                                    }}>
                                        <div className={styles.userContainer}>
                                            <img src={context.user.picture ?? UserPlaceholder} alt="avatar" className={styles.userAvatar} />
                                            {/* <div className={styles.userName}>{`${context.user.name ?? ''} ${context.user.surname ?? ''}`}</div> */}
                                        </div>
                                    </button>
                                </>
                            }
                        </>
                    }
                    {width <= 768 &&
                        <>
                            <Logo className={styles.logo} onClick={() => { navigate("/") }} />

                            <div className={styles.iconButtons}>
                                {!context.user.isAnonymous && context.hasSubscription &&
                                    <button className={styles.iconButton} onClick={() => {
                                        setIsOpen(false)
                                        navigate("/notifications")
                                    }}>
                                        {context.notifications?.metadata.unread === 0 &&
                                            <BellIcon />
                                        }
                                        {context.notifications?.metadata.unread > 0 &&
                                            <>
                                                <BellFillIcon />
                                                <div className={styles.badge}>
                                                    {context.notifications.metadata.unread}
                                                </div>
                                            </>
                                        }
                                    </button>
                                }
                                <Hamburger isOpen={isOpen} onChange={(event) => { setIsOpen(event.target.checked) }}></Hamburger>
                            </div>
                        </>
                    }
                </div>
                <div className={`${styles.content} ${isOpen ? styles.open : ''}`}>
                    {isOpen &&
                        <>
                            {context.hasSubscription &&
                                <button
                                    className={`${styles.menuButton} ${path === '/' ? styles.active : ''}`}
                                    onClick={() => {
                                        setIsOpen(false)
                                        navigate("/")
                                    }}>
                                    {getIcon('')}
                                    Home
                                </button>}
                            {/* <button
                                className={`${styles.menuButton} ${path === '/courses' ? styles.active : ''}`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate("/courses")
                                }}>
                                {getIcon('courses')}
                                Corsi
                            </button>
                            <button
                                className={`${styles.menuButton} ${path === '/careers' ? styles.active : ''}`}
                                onClick={() => {
                                    setIsOpen(false)
                                    navigate("/careers")
                                }}>
                                {getIcon('careers')}
                                Carriere
                            </button> */}

                            {!context.user.isAnonymous && context.hasSubscription &&
                                <button
                                    className={`${styles.menuButton} ${path === '/my-path' ? styles.active : ''}`}
                                    onClick={() => { navigate("/my-path") }}>
                                    {getIcon('my-path')}
                                    Il mio percorso
                                </button>
                            }

                            {/* <button
                                className={`${styles.menuButton} ${path === '/personalities' ? styles.active : ''}`}
                                onClick={() => { navigate("/personalities") }}>
                                {getIcon('personalities')}
                                La mia personalità
                            </button> */}

                            <div className={styles.spacer}></div>

                            {context.user?.isAnonymous &&
                                <Button style={{ width: '100%', padding: '.7rem 0' }}
                                    onClick={() => { navigate("/login") }}>
                                    ACCEDI
                                </Button>
                            }
                            {!context.user.isAnonymous &&
                                <>
                                    <button
                                        className={`${styles.menuButton} ${path === '/messages' ? styles.active : ''}`}
                                        onClick={() => {
                                            setIsOpen(false)
                                            navigate('/messages')
                                        }}>
                                        {getIcon('messages')}
                                        {context.unreadMessages > 0 &&
                                            <div className={styles.iconBadge}>{context.unreadMessages}</div>
                                        }
                                        {isOpen &&
                                            <>
                                                Messaggi
                                            </>
                                        }
                                    </button>
                                    <button className={styles.userButton} onClick={() => {
                                        navigate("/profile")
                                    }}>
                                        <div className={styles.userContainer}>
                                            <img src={context.user.picture ?? UserPlaceholder} alt="avatar" className={styles.userAvatar} />
                                            <div className={styles.userName}>{`${context.user.name ?? ''} ${context.user.surname ?? ''}`}</div>
                                        </div>
                                    </button>
                                </>
                            }

                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default TopNav